.block-picker > div:nth-child(2) {
	height: 35px !important;
}

.block-picker > div:nth-child(2) > div {
	display: none;
}

/* .block-picker > div:nth-child(3) {
	padding: 15px 9px 9px 15px !important;
}

.block-picker > div:nth-child(3) > div:first-child > span > div {
	width: 30px !important;
	height: 30px !important;
	border-radius: 4px !important;
	margin: 0 6px 6px 0 !important;
} */

.block-picker > div:last-child > div:last-child {
	display: none;
}