.chip {
  background-color: #f0f0f0;
  color: #000;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 5px;
}

.chip.selected {
  background-color: var(--color-primary-main) !important;
  color: #fff;
}
