.PageLayout {
  padding: 48px;
  align-items: center;
  justify-content: center;
  align-content: center;
  margin: auto;
  text-align: center;
}

.PageLayout.noPadding {
  padding: 0;
}

.PageLayout.fullWidth {
  width: 100%;
}

.PageLayout.fullHeight {
  height: 100%;
}
