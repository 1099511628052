.toolbar-wrapper {
  max-width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.23);
  padding: 0 8px;
  display: flex;
  align-items: left;

}

.toolbar-wrapper > div {
  display: flex;
  justify-content: center;
  align-items: center;
}
