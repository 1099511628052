.draft-js-toolbar-button button {
  width: 40px !important;
  height: 40px !important;
  min-width: 0px;
  min-height: 0px;
  font-size: 20px;
  background-color: transparent !important;
  border: none;
  font-weight: 'bold';
  padding: 0px !important;
  margin: 0px !important;

}

.draft-js-toolbar-button.inactive button {
  color: #999;
}
.draft-js-toolbar-button.active button {
  color: '--var(primary-color)';
}
.toolbar-wrapper button.text {
  padding-top: 0;
  padding-bottom: 0;
}
