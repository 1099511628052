.Registration {
  height: 100%;
}

.Registration .paddingX {
  padding-left: 16px;
  padding-right: 16px;
}
.Registration .stepperContainer {
  width: 100%;
  background-color: #f5f5f5;
  padding: 18px 16px;
}

.logoContainer {
  display: flex;
  justify-content: center;

  background-color: red;
  max-width: 250px;
  height: auto;
  border: none;
  border-radius: 0;
  padding: 0;
}

.Registration .formPadding {
  padding: 12px 16px 0px 16px;
}
