.chrome-picker {
	box-shadow: unset !important;
}

.chrome-picker > div:nth-child(2) {
	position: relative;
	border: 1px solid rgba(0, 0, 0, .15);
}

.chrome-picker > div:nth-child(2) > div:nth-child(2) > div:nth-child(2) {
	display: none;
}

@media (max-width: 600px) {
	.chrome-picker > div:nth-child(2) > div:nth-child(2) {
		padding-top: 0 !important;
	}

	.chrome-picker > div:nth-child(2) > div:nth-child(1) {
		margin-bottom: 40px;
	}
}