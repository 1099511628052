.powered-by {
  display: flex;
  justify-content: end;
  align-items: end;

  .label {
    font-size: 0.8rem;
    color: #999;
    margin-right: 12px;
  }
  .imgContainer {
    padding: 0;
    width: auto !important;
  }
  .img {
    max-width: 10vh;
    height: 25px;
    border-radius: 0%;
  }
}
