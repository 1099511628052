.container {
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  padding: 0px;
}

.container.TaglineFit {
  width: 100%;
  border-radius: 0%;
}

.container.Fit {
  width: 100%;
  border-radius: 0%;
}

.image {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.image.TaglineFit {
  width: 100%;
  height: auto;
  border-radius: 0%;
}

.image.Fit {
  width: 100%;
  height: auto;
  border-radius: 0%;
}
