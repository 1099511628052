.textFieldContainer {
	display: flex;
	flex-direction: column;
}

.textField {
	width: 100%;
}

.selectField {
	width: 100%;
	padding-left: 0;
}

.switchField {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.fieldContainer {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.fieldLabelContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.sliderContainer {
	padding: 0 8px;
}

.numberFormField {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.numberField {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
}

.number {
	border-width: 0px 0px 1px 0px;
	text-align: center;
	float: right;
	outline: none;
}

.tagsContainer {
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
}

.formField {
	display: flex;
	align-items: center;
}

.datePicker {
	width: 100%;
}