.background {
  width: 100%;
  height: 100%;
  min-height: min(100%, 700px);
  padding: 0px;
  position: relative;
  overflow-x: hidden;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
}

.navContainer {
  position: fixed;
  height: calc(100% - 64px);
  display: flex;
  align-items: center;
  left: 32px;
}

.layout {
  width: 100%;
  /* height: 100%; */
}

.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  right: 12px;
  top: 12px;
}

.footer {
  width: 100vw;
  display: block;

  /* padding: 16px; */
  /* position: fixed; */
  /* bottom: 12px; */
  .fab{
    grid-template-columns: 2fr 1fr;
    gap: 8px;  
    padding: 0px 12px;
    margin: auto;
    position: fixed;
    bottom: 12px;
    width: 100vw;
    display: flex;
    justify-content: center;
    z-index: 1;
  }
}

.cards {
  position: relative;
  display: flex;
}

.xl-footer {
  margin-top: calc(100vh - 750px - 12px);
  z-index: 1;
}

@media screen and (min-height: 750px) {
  .xl-footer {
    margin-top: 0;
  }
}
