button.emphasis {
  padding: 8px;
  font-weight: bold;
  font-size: 1.1rem;
}

button.primary {
  padding: 8px;
  font-weight: bold;
  font-size: 1.1rem;
}

button.secondary {
  padding: 8px;
  font-weight: bold;
  font-size: 1.1rem;
}
button.low {
  padding: 4px;
  font-size: 0.9rem;
  color: grey;
}

.p1 {
  padding: 4px;
}
.p2 {
  padding: 8px;
}
.p3 {
  padding: 16px;
}

.m1 {
  margin: 8px;
}
.mb1 {
  margin-bottom: 8px;
}

.w100 {
  width: 100%;
}

.h100 {
  height: 100%;
}

.StandardPageContainer{
  width: 100%;
}

@media screen and (min-height:784px) {
  .StandardPageContainer{
    height: 100%;
    align-content: center;
  }  
}