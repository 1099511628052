.personalityTypeOptions > div {
	background-color: rgba(0, 0, 0, 0.06);
	transition: .3s all;
}

.active h6 {
	font-weight: bold;
}

.active.D > div {
	background-color: var(--color-tag-red-200);
}

.active.I > div {
	background-color: var(--color-tag-yellow-200);
}

.active.S > div {
	background-color: var(--color-tag-green-200);
}

.active.C > div {
	background-color: var(--color-purple);
}