@value mobile, tablet from "styles/breakpoints.css";

.pageContainer {
  height: calc(100vh - 64px);
  background-color: var(--background-default-light);
  padding: 32px;
  overflow: scroll;
}

.frameContainer {
  height: 100vh;
  background-color: var(--background-default-light);
  padding: 16px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.avatarContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 5px solid var(--color-blue-300);
  width: 140px;
  height: 140px;
  border-radius: 50%;
  overflow: hidden;
}

.avatar {
  width: 108px;
  height: 108px;
  object-fit: cover;
}

.stepperContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0px;
  height: calc(100vh - 250px);
  min-height: 500px;
}

@media tablet {
  .stepperContainer {
    height: auto;
    min-height: auto;
    background-color: transparent !important;
    box-shadow: unset !important;
    margin-bottom: 24px !important;
  }
}

@media mobile {
  .pageContainer {
    padding: 16px;
  }
  .stepperContainer {
    margin-bottom: 16px !important;
  }
  .avatarContainer {
    width: 120px;
    height: 120px;
    border: 3px solid var(--color-blue-300);
  }
}
