.colorCircleIcon {
	width: 0.5em;
	height: 0.5em;
}

.colorCircleIcon.sm {
	width: 0.4em;
	height: 0.4em;
}

.colorCircleIcon.xl {
	width: 1.2em;
	height: 1.2em;
}

.roundedSquareIcon {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 28px;
	height: 28px;
	border-radius: 8px;
	color: white;
	margin-right: 8px;
	cursor: pointer;
}

.customBtn {
	background-image: url('../../../assets/icons/custom.svg');
}
