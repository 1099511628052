.DraftEditor-wrap {
  background-color: white;
  margin-top: 0;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
}

.DraftEditor-root {
  background-color: white;
  border-radius: 0 0 5px 5px;
  min-height: 176px;
  padding: 16px;
  box-shadow: 0 1px 3px 0 gainsboro;
  margin-top: 0;
  font-size: 16px;
}

.DraftEditor-editorContainer {
  cursor: text;
}

.DraftEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.DraftEditor-tools {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 14px;
  user-select: none;
  min-height: 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.23);
  padding: 0 22px;
}

.DraftEditor-styleButton {
  color: var(--color-text-primary);
  cursor: pointer;
  margin-right: 20px;
  padding: 0;
  display: inline-block;
  border: none;
  background-color: transparent;
}

.DraftEditor-separating {
  padding-right: 20px;
  border-right: 1px solid rgba(65, 85, 110, 0.38);
}

.DraftEditor-activeButton {
  color: #5890ff;
}

.DraftEditor-align-left > div {
  text-align: left;
}

.DraftEditor-align-center > div {
  text-align: center;
}
.DraftEditor-align-right > div {
  text-align: right;
}

@media (max-width: 600px) {
  .DraftEditor-root {
    min-height: 248px;
  }
  .DraftEditor-tools {
    padding: 8px;
  }
  .DraftEditor-styleButton {
    margin-right: 10px;
  }
  .DraftEditor-separating {
    padding-right: 0;
    border-right: 0;
  }
}

.emoji-picker {
  .emoji-mart {
    border: none;

    .emoji-mart-bar {
      border: none;
    }
  }
}
