.container {
  height: 100%;
  justify-content: center

}

.pagination {
  text-align: center;
}

.cardsContainer {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 0 56px 0;
}

.swiperPagination {
  position: relative !important;
  width: 100% !important;
  top: unset !important;
  left: unset !important;
  right: unset !important;
  bottom: unset !important;
}

.mediaControl {
  display: block;
}

/* @media screen and (min-height: 700px) {
  .mediaControl {
  }
} */

.swiperButtonPrev {
  position: absolute !important;
  left: 8px;
  z-index: 5;
}

.swiperButtonNext {
  position: absolute !important;
  right: 8px;
  z-index: 5;
}
