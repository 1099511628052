.largeIconButtonWrapper {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 0;
  min-width: 72px;
}

.largeIconButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 0;
  padding-right: 0;
  cursor: pointer;
}