.image1 {
  z-index: -1;
  width: 150vw;
  height: 100%;
  object-fit: cover;
  position: relative;
  left: -20%;
  margin-bottom: -20%;
  rotate: -30deg;
  box-shadow: 20px 5px 50px 20px rgba(200, 200, 200, 1);
}
