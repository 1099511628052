.mainTopGraphics {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.otherTopGraphics {
  position: absolute;
  top: 0;
  left: 0;
  
}

.bottomGraphics {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
}
