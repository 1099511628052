.paidFeatureAlert {
  border-radius: 16px 0px;
}

.paidFeatureAlert .heading {
  font-size: 18px;
  color: #161616;
  font-weight: 600;
}

.paidFeatureAlert .description {
  font-size: 14px;
  color: #616161;
}

.paidFeatureAlert .firstCTA {
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

@media screen and (min-width: 600px) {
  .paidFeatureAlert {
    padding: 0px;
  }

  .paidFeatureAlert .heading {
    padding-top: 0px;
  }
}
