.uploadAvatarDialog div[role="dialog"] {
	margin: 32px 8px;
	width: calc(100% - 16px);
}

.uploadAvatarDialog input[type="file"] {
    display: none;
  }

.uploadAvatarDialog  input[type="range"] {
	padding: 0 8px;
}
