@value mobile from "styles/breakpoints.css";

.radioCardsContainer {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 8px;
}

.radioCard {
	flex: 1;
	display: flex;
	align-items: center;
	height: 48px;
	border: 1px solid rgba(0, 0, 0, 0.23);
	border-radius: 12px;
	padding: 12px;
	cursor: pointer;
}

.selectedCard {
	color: white;
	background-color: var(--color-primary-main);
}

.themeOptions {
	position: relative;
	cursor: pointer;
	background-color: transparent;
}

.themeOptions.selectedCard::after {
	content: '';
	position: absolute;
	top: 8px;
	left: 6px;
	width: 16px;
	height: 12px;
	background-image: url('../assets/icons/check.svg');
}

@media mobile {
	.radioCardsContainer {
		flex-wrap: wrap;
	}
	.radioCard {
		flex: 0 0 calc(50% - 8px);
		max-width: calc(50% - 8px);
	}
}