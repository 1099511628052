@value mobile, tablet from "styles/breakpoints.css";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.fieldsGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fieldsGroup > * {
  flex: 0 0 calc(50% - 12px);
}

.footer {
  display: flex;
  align-items: center;
  margin-top: auto;
  /* padding-top: 32px; */
}

.selected {
  position: relative;
}

.selected::after {
  content: '';
  position: absolute;
  top: 8px;
  left: 6px;
  width: 16px;
  height: 12px;
  background-image: url('../../../assets/icons/check.svg');
}

.inactive {
  background-color: var(--color-blue-300) !important;
  color: white !important;
}

.subtitle {
  display: block;
  margin-top: 24px;
  margin-bottom: 8px;
}

.required {
  color: red !important;
}

.remark {
  display: inline-block;
  opacity: 0.6;
  padding-left: 4px;
}

.blue {
  color: var(--color-blue-400);
}

.themeBg {
  margin: 0 auto 24px;
}

.hasBtnFieldsContainer {
  position: relative;
}

.hasBtnFieldsContainer > button {
  position: absolute;
  top: 42%;
  transform: translateY(-50%);
  right: 0;
}

@media mobile {
  .fieldsGroup:not(.twoColumns) {
    flex-direction: column;
  }
  .fieldsGroup:not(.twoColumns) > * {
    flex: 0 0 calc(100% - 6px);
    width: calc(100% - 6px);
  }
  .subtitle {
    margin-top: 16px;
  }
  .themeBg {
    max-width: 100%;
  }
}
